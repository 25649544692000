/*---------------------
     VARIABLES
----------------------*/

/* Color Variables */

/* Color Variables */

.btn {
    text-transform: capitalize;
    position: relative;
    font-size: 14px;
    font-weight: 700;
    width: 170px;
    height: 50px;
    line-height: 48px;
    border-radius: 3px;
    text-align: center;
    padding: 0;
    letter-spacing: .4px;
    border-radius: 2px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}

.btn:focus,
.btn.active,
.btn:active {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}

.btn__block {
    width: 100%;
}

/* Button Primary */
.btn__primary {
    background-color: #ff5e14;
    color: #ffffff;
    border: 2px solid #ff5e14;
}

.btn__primary:active,
.btn__primary:focus,
.btn__primary:hover {
    background-color: transparent;
    color: #ff5e14;
}

.btn__primary.btn__hover2:active,
.btn__primary.btn__hover2:focus,
.btn__primary.btn__hover2:hover {
    background-color: #ffffff;
    color: #ff5e14;
    border-color: #ffffff;
}

.btn__primary.btn__hover3:active,
.btn__primary.btn__hover3:focus,
.btn__primary.btn__hover3:hover {
    background-color: #121c45;
    color: #ffffff;
    border-color: #121c45;
}

/* Button Secondary*/
.btn__secondary {
    background-color: #121c45;
    color: #ffffff;
    border: 2px solid #121c45;
}

.btn__secondary:active,
.btn__secondary:focus,
.btn__secondary:hover {
    background-color: #ff5e14;
    color: #ffffff;
    border-color: #ff5e14;
}

.btn__secondary.btn__hover2:active,
.btn__secondary.btn__hover2:focus,
.btn__secondary.btn__hover2:hover {
    background-color: #ffffff;
    color: #121c45;
    border-color: #ffffff;
}

/* Button White */
.btn__white {
    background-color: #ffffff;
    color: #121c45;
    border: 2px solid #ffffff;
}

.btn__white:active,
.btn__white:focus,
.btn__white:hover {
    background-color: #ff5e14;
    color: #ffffff;
    border-color: #ff5e14;
}

.btn__white.btn__hover2:active,
.btn__white.btn__hover2:focus,
.btn__white.btn__hover2:hover {
    background-color: #121c45;
    color: #ffffff;
    border-color: #121c45;
}

.btn__rounded {
    border-radius: 50px;
}

/* Button Link */
.btn__link {
    background-color: transparent;
    border-color: transparent;
    width: auto;
    height: auto;
    line-height: 1;
    border: none;
}

.btn__link.btn__primary {
    color: #ff5e14;
}

.btn__link.btn__primary:active,
.btn__link.btn__primary:focus,
.btn__link.btn__primary:hover {
    color: #121c45;
    background-color: transparent;
    border-color: transparent;
}

.btn__link.btn__secondary {
    color: #121c45;
}

.btn__link.btn__secondary:active,
.btn__link.btn__secondary:focus,
.btn__link.btn__secondary:hover {
    color: #ff5e14;
    background-color: transparent;
    border-color: transparent;
}

.btn__link.btn__white {
    color: #ffffff;
}

.btn__link.btn__white:active,
.btn__link.btn__white:focus,
.btn__link.btn__white:hover {
    color: #ffffff;
    background-color: transparent;
    border-color: transparent;
}

/* Button Bordered */

.btn__bordered {
    background-color: transparent;
}

.btn__bordered.btn__primary {
    color: #ff5e14;
    border-color: #ff5e14;
}

.btn__bordered.btn__primary:active,
.btn__bordered.btn__primary:focus,
.btn__bordered.btn__primary:hover {
    color: #ffffff;
    background-color: #ff5e14;
    border-color: #ff5e14;
}

.btn__bordered.btn__secondary {
    color: #121c45;
    border-color: #121c45;
}

.btn__bordered.btn__secondary:active,
.btn__bordered.btn__secondary:focus,
.btn__bordered.btn__secondary:hover {
    color: #ffffff;
    background-color: #121c45;
    border-color: #121c45;
}

.btn__bordered.btn__white {
    color: #ffffff;
    border-color: #ffffff;
}

.btn__bordered.btn__white:active,
.btn__bordered.btn__white:focus,
.btn__bordered.btn__white:hover {
    color: #ff5e14;
    background-color: #ffffff;
    border-color: #ffffff;
}

.btn__white.btn__secondary {
    color: #121c45;
    border-color: #ffffff;
    background-color: #ffffff;
}

.btn__white.btn__secondary:active,
.btn__white.btn__secondary:focus,
.btn__white.btn__secondary:hover {
    color: #ffffff;
    background-color: #ff5e14;
    border-color: #ff5e14;
}

.btn__video.popup-video {
    padding-left: 50px;
    color: #ffffff;
}

.btn__video-lg.popup-video {
    padding-left: 100px;
    width: 200px;
    height: 75px;
    line-height: 75px;
}

.btn__lg {
    width: 220px;
}

.btn__underlined {
    padding-bottom: 5px;
}

.btn__underlined:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ff5e14;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}

.btn__white.btn__underlined:after {
    background-color: #ffffff;
}

.btn__white.btn__underlined:hover {
    color: #121c45;
}

.btn__white.btn__underlined:hover:after {
    background-color: #121c45;
}

.btn span+i {
    margin-left: 6px;
}


/*-----------------------
    Alert
-----------------------*/
.alert {
    background-color: #121c45;
    padding: 24px 20px;
    border-radius: 0;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    border-radius: 3px;
    border: none;
}

/*-------------------------
   Background & Colors
--------------------------*/

/* Colors */

.color-white {
    color: #ffffff !important;
}

.color-gray {
    color: #f9f9f9 !important;
}

.color-dark {
    color: #282828 !important;
}

.color-theme {
    color: #ff5e14 !important;
}

.color-heading {
    color: #121c45 !important;
}

.color-body {
    color: #9b9b9b !important;
}

/* backgrounds */

.bg-white {
    background-color: #ffffff !important;
}

.bg-gray {
    background-color: #f9f9f9 !important;
}

.bg-dark {
    background-color: #282828 !important;
}

.bg-heading {
    background-color: #121c45 !important;
}

.bg-theme {
    background-color: #ff5e14 !important;
}

.bg-img {
    position: relative;
    z-index: 1;
}

.bg-parallax {
    background-attachment: fixed;
}

.bg-overlay:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(27, 26, 26, 15%);
}

.bg-overlay-2:before {
    background-color: rgba(27, 26, 26, 25%);
}

.bg-overlay-3:before {
    background-color: rgba(27, 26, 26, 75%);
}

.bg-overlay-secondary:before {
    background-color: rgba(18, 28, 69, 90%);
}

.bg-overlay-theme:before {
    background-color: rgba(255, 94, 20, 90%);
}

.bg-overlay-grdient-theme:before {
    background-color: rgba(255, 94, 20, 90%);
    background-image: -webkit-linear-gradient(90deg, rgba(27, 26, 26, 0) 0%, rgba(255, 94, 20, 0) 0%, rgb(255, 94, 20) 65%);
    background-image: -moz-linear-gradient(90deg, rgba(27, 26, 26, 0) 0%, rgba(255, 94, 20, 0) 0%, rgb(255, 94, 20) 65%);
    background-image: -ms-linear-gradient(90deg, rgba(27, 26, 26, 0) 0%, rgba(255, 94, 20, 0) 0%, rgb(255, 94, 20) 65%);
}

.bg-overlay-grdient-secondary:before {
    background-color: rgba(18, 28, 69, 75%);
    background-image: -webkit-linear-gradient(-90deg, rgba(27, 26, 26, 0) 0%, rgba(5, 16, 59, 0) 0%, rgb(5, 16, 59) 65%);
    background-image: -moz-linear-gradient(-90deg, rgba(27, 26, 26, 0) 0%, rgba(5, 16, 59, 0) 0%, rgb(5, 16, 59) 65%);
    background-image: -ms-linear-gradient(-90deg, rgba(27, 26, 26, 0) 0%, rgba(5, 16, 59, 0) 0%, rgb(5, 16, 59) 65%);
}

.bg-overlay-gradient-secondary-2:before {
    background-color: rgba(18, 28, 69, 95%);
    background-image: -webkit-linear-gradient(90deg, rgba(27, 26, 26, 0) 0%, rgba(5, 16, 59, 0) 0%, rgb(5, 16, 59) 65%);
    background-image: -moz-linear-gradient(90deg, rgba(27, 26, 26, 0) 0%, rgba(5, 16, 59, 0) 0%, rgb(5, 16, 59) 65%);
    background-image: -ms-linear-gradient(90deg, rgba(27, 26, 26, 0) 0%, rgba(5, 16, 59, 0) 0%, rgb(5, 16, 59) 65%);
}
/*-------------------------
    Breadcrumb
--------------------------*/
.breadcrumb {
    background-color: transparent;
    padding: 0;
}

.breadcrumb-item {
    line-height: 1;
}

.breadcrumb-item+.breadcrumb-item::before {
    font-family: FontAwesome;
    content: "\f105";
}

/*---------------------------
        Forms
----------------------------*/
label {
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 15px;
    color: #121c45;
}

.form-group {
    position: relative;
    margin-bottom: 30px;
}

.form-control {
    height: 50px;
    line-height: 50px;
    border: none;
    border: 1px solid #eaeaea;
    border-radius: 1px;
    padding: 0 0 0 20px;
    font-size: 14px;
    border-radius: 2px;
}

.form-control:focus {
    border-color: #ff5e14;
}

textarea.form-control {
    height: 122px;
    padding-top: 10px;
    line-height: 25px;
}

.form-control::-webkit-input-placeholder {
    color: #9b9b9b;
}

.form-control:-moz-placeholder {
    color: #9b9b9b;
}

.form-control::-moz-placeholder {
    color: #9b9b9b;
}

.form-control:-ms-input-placeholder {
    color: #9b9b9b;
}

.form__title {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 20px;
}

.form-group-select:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

.form-group-select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #9b9b9b;
    cursor: pointer;
}

/* Input Radio */

.label-radio {
    display: block;
    position: relative;
    padding-left: 26px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    color: #9b9b9b;
}

.label-radio input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.radio-indicator {
    position: absolute;
    top: -1px;
    left: 0;
    height: 17px;
    width: 17px;
    background: transparent;
    border: 2px solid #eaeaea;
    border-radius: 50%;
}

.label-radio input:checked ~ .radio-indicator {
    background: transparent;
}

.label-radio:hover input:not([disabled]):checked ~ .radio-indicator,
.label-radio input:checked:focus ~ .radio-indicator {
    background: transparent;
}

.radio-indicator:after {
    content: '';
    position: absolute;
    display: none;
    left: 3px;
    top: 3px;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: #ff5e14;
}

.label-radio input:checked ~ .radio-indicator:after {
    display: block;
}

/*----------------------------
      Helper Classes
----------------------------*/
.col-padding-0>.row {
    margin: 0;
  }
  
  .col-padding-0,
  .col-padding-0>.row>[class*="col-"] {
    padding: 0;
  }
  
  .inner-padding {
    padding: 120px 70px;
  }
  
  .width-auto {
    width: auto !important;
  }
  
  .fz-16 {
    font-size: 16px;
  }
  
  .font-secondary {
    font-family: 'Roboto', sans-serif;
  }
  
  .lh-1 {
    line-height: 1 !important;
  }
  
  .vh-100 {
    height: 100vh !important;
  }
  
  .list-inline>li {
    display: inline-block;
  }
  
  .align-v {
    display: flex;
    align-items: center;
  }
  
  .align-v-h {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .border-top {
    border-top: 1px solid #eaeaea !important;
  }
  
  .border-bottom {
    border-bottom: 1px solid #eaeaea !important;
  }
  
  .flex-1 {
    flex: 1;
  }
  
  @media (min-width: 992px) {
    .col-lg-5th {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
  
  /* Margin Top */
  
  .mt-0 {
    margin-top: 0 !important;
  }
  
  .mt-5 {
    margin-top: 5px !important;
  }
  
  .mt-10 {
    margin-top: 10px !important;
  }
  
  .mt-20 {
    margin-top: 20px !important;
  }
  
  .mt-30 {
    margin-top: 30px !important;
  }
  
  .mt-40 {
    margin-top: 40px !important;
  }
  
  .mt-50 {
    margin-top: 50px !important;
  }
  
  .mt-60 {
    margin-top: 60px !important;
  }
  
  .mt-70 {
    margin-top: 70px !important;
  }
  
  .mt-80 {
    margin-top: 80px !important;
  }
  
  .mt-90 {
    margin-top: 90px !important;
  }
  
  .mt-100 {
    margin-top: 100px !important;
  }
  
  .mt--100 {
    margin-top: -100px;
  }
  
  /* Margin Bottom */
  
  .mb-0 {
    margin-bottom: 0 !important;
  }
  
  .mb-5 {
    margin-bottom: 5px !important;
  }
  
  .mb-10 {
    margin-bottom: 10px !important;
  }
  
  .mb-20 {
    margin-bottom: 20px !important;
  }
  
  .mb-25 {
    margin-bottom: 25px !important;
  }
  
  .mb-30 {
    margin-bottom: 30px !important;
  }
  
  .mb-40 {
    margin-bottom: 40px !important;
  }
  
  .mb-45 {
    margin-bottom: 45px !important;
  }
  
  .mb-50 {
    margin-bottom: 50px !important;
  }
  
  .mb-60 {
    margin-bottom: 60px !important;
  }
  
  .mb-70 {
    margin-bottom: 70px !important;
  }
  
  .mb-80 {
    margin-bottom: 80px !important;
  }
  
  .mb-90 {
    margin-bottom: 90px !important;
  }
  
  .mb-100 {
    margin-bottom: 100px !important;
  }
  
  /* Margin Right */
  
  .mr-0 {
    margin-right: 0 !important;
  }
  
  .mr-20 {
    margin-right: 20px !important;
  }
  
  .mr-30 {
    margin-right: 30px !important;
  }
  
  .mr-40 {
    margin-right: 40px !important;
  }
  
  .mr-50 {
    margin-right: 50px !important;
  }
  
  /* Margin Left */
  
  .ml-0 {
    margin-left: 0 !important;
  }
  
  .ml-20 {
    margin-left: 20px !important;
  }
  
  .ml-30 {
    margin-left: 30px !important;
  }
  
  .ml-40 {
    margin-left: 40px !important;
  }
  
  .ml-50 {
    margin-left: 50px !important;
  }
  
  /* Padding Top */
  
  .pt-10 {
    padding-top: 10px !important;
  }
  
  .pt-20 {
    padding-top: 20px !important;
  }
  
  .pt-30 {
    padding-top: 30px !important;
  }
  
  .pt-40 {
    padding-top: 40px !important;
  }
  
  .pt-50 {
    padding-top: 50px !important;
  }
  
  .pt-60 {
    padding-top: 60px !important;
  }
  
  .pt-70 {
    padding-top: 70px !important;
  }
  
  .pt-80 {
    padding-top: 80px !important;
  }
  
  .pt-90 {
    padding-top: 90px !important;
  }
  
  .pt-100 {
    padding-top: 100px !important;
  }
  
  .pt-110 {
    padding-top: 110px !important;
  }
  
  .pt-120 {
    padding-top: 120px !important;
  }
  
  .pt-130 {
    padding-top: 130px !important;
  }
  
  .pt-140 {
    padding-top: 140px !important;
  }
  
  .pt-150 {
    padding-top: 150px !important;
  }
  
  .pt-170 {
    padding-top: 170px !important;
  }
  
  /* Padding Bottom */
  
  .pb-10 {
    padding-bottom: 10px !important;
  }
  
  .pb-20 {
    padding-bottom: 20px !important;
  }
  
  .pb-30 {
    padding-bottom: 30px !important;
  }
  
  .pb-40 {
    padding-bottom: 40px !important;
  }
  
  .pb-50 {
    padding-bottom: 50px !important;
  }
  
  .pb-60 {
    padding-bottom: 60px !important;
  }
  
  .pb-70 {
    padding-bottom: 70px !important;
  }
  
  .pb-80 {
    padding-bottom: 80px !important;
  }
  
  .pb-90 {
    padding-bottom: 90px !important;
  }
  
  .pb-100 {
    padding-bottom: 100px !important;
  }
  
  .pb-110 {
    padding-bottom: 110px !important;
  }
  
  .pb-120 {
    padding-bottom: 120px !important;
  }
  
  .pb-130 {
    padding-bottom: 130px !important;
  }
  
  .pb-140 {
    padding-bottom: 140px !important;
  }
  
  .pb-150 {
    padding-bottom: 150px !important;
  }
  
  .pb-170 {
    padding-bottom: 170px !important;
  }
/*------------------- 
    Icons
------------------*/

.social__icons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #121c45;
}

.social__icons a {
    margin-right: 20px;
    color: #121c45;
    transition: all .3s linear;
}

.social__icons a:hover {
    color: #ff5e14;
}

.social__icons a:last-of-type {
    margin-right: 0;
}
/*-------------------
    lists
-------------------*/
.contact__list li {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 9px;
}

.contact__list li:last-child {
  margin-bottom: 0;
}

.contact__list li i {
  font-size: 16px;
  margin-right: 9px;
  color: #ff5e14; 
}

.list-items li {
  position: relative;
  padding-left: 26px;
  font-family: 'Poppins', sans-serif; 
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.list-items li:before {
  content: "\f00c";
  font-family: 'Fontawesome';
  position: absolute;
  left: 0;
}

.list-items-white li {
  color: #ffffff;
}

.list-items-white li:before {
  color: #ffffff;
}

.list-items-layout2 li {
  font-weight: 400;
}

.list-items-layout2 li:before {
  color: #ff5e14; 
}
  
/*-------------------------
    page title 
-------------------------*/

.header-transparent+.page-title {
  margin-top: -100px;
}

.pagetitle__subheading {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #f9f9f9;
  display: block;
  margin-bottom: 21px;
}

.pagetitle__heading {
  font-size: 57px;
  color: #ffffff;
  margin-bottom: 0;
}

.pagetitle__desc {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #f9f9f9;
  margin-bottom: 0;
}

.page-title {
  padding: 241px 0 148px;
}

.page-title .breadcrumb {
  margin-top: 15px;
  margin-bottom: 0;
}

.page-title .breadcrumb-item a,
.page-title .breadcrumb-item+.breadcrumb-item::before {
  color: #ffffff;
}

.page-title .breadcrumb-item a:hover {
  color: #ff5e14;
}

.page-title .breadcrumb-item.active {
  color: #f4f4f4;
}

.page-title-layout2 .pagetitle__heading {
  font-size: 47px;
  line-height: 62px;
  margin-bottom: 15px;
}

.page-title-layout2 .btn {
  margin-top: 35px;
}

.page-title-layout3 .pagetitle__heading {
  font-size: 47px;
  font-weight: 700;
  line-height: 62px;
  margin-bottom: 18px;
}

.page-title-layout3 .btn {
  margin-top: 32px;
}

.page-title-layout4 {
  padding: 248px 0 115px;
}

.page-title-layout4 .pagetitle__heading {
  font-size: 47px;
  font-weight: 700;
  line-height: 62px;
  margin-bottom: 18px;
}

@media only screen and (min-width: 768px) {
  .page-title {
      padding: 150px 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .page-title {
      padding: 100px 0 50px;
  }

  .pagetitle__heading {
      font-size: 35px;
  }

  .page-title-layout2 .pagetitle__heading,
  .page-title-layout3 .pagetitle__heading,
  .page-title-layout4 .pagetitle__heading {
      font-size: 33px;
      line-height: 40px;
      margin-bottom: 5px;
  }

  .pagetitle__desc {
      font-weight: 400;
  }

  .page-title .breadcrumb {
      margin-top: 10px;
  }
}

/*----------------------
    Pagination
-----------------------*/
.pagination li {
  margin-right: 10px;
}

.pagination li:last-child {
  margin-right: 0;
}

.pagination li a {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 700;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 48px;
  background-color: transparent;
  color: #121c45; /* $color-heading */
  text-align: center;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}

.pagination li a:hover,
.pagination li a.current {
  background-color: #121c45; /* $color-heading */
  border-color: #121c45; /* $color-heading */
  color: #ffffff; /* $color-white */
}

@media only screen and (max-width: 767px) {
  .pagination li a {
      font-size: 18px;
      width: 40px;
      height: 40px;
      line-height: 38px;
  }
}
/*--------------------
    Tabs
--------------------*/

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav__link {
  display: block;
  position: relative;
  padding: 0 0 6px;
  margin: 0 30px 20px 0;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #121c45; /* $color-heading */
}

.nav-tabs .nav__link:last-of-type {
  margin-right: 0;
}

.nav-tabs .nav__link:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ff5e14; /* $color-theme */
  transition: all .7s linear;
  -webkit-transition: all .7s linear;
  -moz-transition: all .7s linear;
  -ms-transition: all .7s linear;
  -o-transition: all .7s linear;
}

.nav-tabs .nav__link.active,
.nav-tabs .nav__link:hover {
  color: #ff5e14; /* $color-theme */
}

.nav-tabs .nav__link.active:after,
.nav-tabs .nav__link:hover:after {
  width: 100%;
}

.nav-tabs-white .nav__link {
  color: #ffffff; /* $color-white */
}

.nav-tabs-white .nav__link.active,
.nav-tabs-white .nav__link:hover {
  color: #ffffff; /* $color-white */
}

.nav-tabs-white .nav__link:after {
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .nav-tabs .nav__link {
      margin: 0 15px 10px 0;
  }
}

/*----------------------------
     Testimonial
------------------------------*/
.testimonial-item {
  position: relative;
}

.testimonial-item .testimonial__rating {
  line-height: 1;
  margin-bottom: 21px;
}

.testimonial-item .testimonial__rating i {
  color: #ff5e14;
}

.testimonial-item .testimonial__desc {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 21px;
}

.testimonial__meta {
  position: relative;
  z-index: 2;
}

.testimonial__meta img {
  max-width: 110px;
  display: inline-block !important;
}

.testimonial__meta-title {
  font-family: 'Roboto', sans-serif;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 2px;
}

.testimonial__meta-desc {
  font-family: 'Roboto', sans-serif;
  line-height: 25px;
  font-weight: 400;
  font-size: 12px;
  color: #616161;
  margin-bottom: 0;
}

.testimonial__thumb {
  width: 54px;
  height: 54px;
  border: 2px solid #ff5e14;
  overflow: hidden;
  border-radius: 50%;
  padding: 2px;
  display: inline-block;
}

.testimonial__thumb img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.testimonial-1 .testimonial-item {
  padding: 0 70px;
}

.testimonial-1 .testimonial-item .testimonial__desc {
  font-size: 21px;
  line-height: 34px;
  font-weight: 500;
  margin-bottom: 30px;
}

.testimonial-1 .testimonial-item .testimonial__content {
  position: relative;
  padding-top: 30px;
}

.testimonial-1 .testimonial-item .testimonial__content:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 60px;
  width: 100px;
  height: 70px;
  display: inline-block;
  z-index: -1;
  background-image: url(../images/testimonials/quote-icon.png);
}

.testimonial-2 .testimonial-item {
  background-color: #fff;
  padding: 35px 20px 30px;
  border-radius: 5px;
  margin: 22px 0;
}

.testimonial-2 .testimonial-item:before {
  content: "";
  position: absolute;
  top: -22px;
  left: 40px;
  width: 60px;
  height: 43px;
  display: inline-block;
  background-image: url(../images/testimonials/quote-icon2.png);
}

.testimonial-2 .testimonial-item:after {
  content: "";
  position: absolute;
  left: 40px;
  bottom: -19px;
  width: 26px;
  height: 20px;
  border-top: 10px solid #fff;
  border-left: 13px solid #fff;
  border-right: 13px solid transparent;
  border-bottom: 10px solid transparent;
}

.testimonial-2 .testimonial-item .testimonial__thumb {
  margin-bottom: 18px;
}

.testimonial-3 .testimonial-panel-wrap {
  background-color: #fff;
  position: relative;
  padding: 74px 60px 80px;
  border-radius: 5px;
  box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.08);
}

.testimonial-3 .testimonial-panel-wrap:before {
  content: "";
  position: absolute;
  top: -22px;
  left: 60px;
  width: 90px;
  height: 64px;
  display: inline-block;
  background-image: url(../images/testimonials/quote-icon3.png);
}

.testimonial-3 .testimonial-panel-wrap:after {
  content: "";
  position: absolute;
  left: 40px;
  bottom: -19px;
  width: 26px;
  height: 20px;
  border-top: 10px solid #fff;
  border-left: 13px solid #fff;
  border-right: 13px solid transparent;
  border-bottom: 10px solid transparent;
}

.testimonial-3 .testimonial-item .testimonial__desc {
  font-size: 20px;
  line-height: 34px;
  font-weight: 500;
  margin-bottom: 27px;
}

.testimonial-3 .testimonial-item .testimonial__thumb {
  margin-right: 15px;
}

.testimonial-3 .carousel-arrows .owl-nav .owl-prev,
.testimonial-3 .carousel-arrows .owl-nav .owl-next {
  background-color: #ffffff;
  color: #121c45;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  top: auto;
  bottom: 0;
  overflow: hidden;
  transform: translateY(0);
}

.testimonial-3 .carousel-arrows .owl-nav .owl-prev:hover,
.testimonial-3 .carousel-arrows .owl-nav .owl-next:hover {
  background-color: #121c45;
  border-color: #121c45;
  color: #ffffff;
}

.testimonial-3 .carousel-arrows .owl-nav .owl-prev {
  left: auto;
  right: 50px;
}

.testimonial-3 .carousel-arrows .owl-nav .owl-next {
  right: 0;
}

.testimonial-3 .carousel-arrows .owl-nav .owl-prev:before,
.testimonial-3 .carousel-arrows .owl-nav .owl-next:before {
  font-family: 'Font Awesome 5 Free';
  font-size: 10px;
}

.testimonial-4 {
  border-radius: 4px;
  overflow: hidden;
}

.testimonial-4 .testimonial-panel-wrap {
  padding: 60px;
}

.testimonial-4 .testimonial-item {
  max-width: 370px;
}

@media only screen and (min-width: 768px) {
  .testimonial-1 .testimonial-item {
      padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__desc {
      font-size: 15px;
      line-height: 27px;
      font-weight: 500;
      margin-bottom: 15px;
  }

  .testimonial-1 .testimonial-item {
      padding: 0 20px;
  }

  .testimonial-1 .carousel-arrows .owl-nav .owl-prev {
      left: -20px;
  }

  .testimonial-1 .carousel-arrows .owl-nav .owl-next {
      right: -20px;
  }

  .testimonial-1 .carousel-arrows .owl-nav .owl-prev:before,
  .testimonial-1 .carousel-arrows .owl-nav .owl-next:before {
      font-size: 30px;
  }

  .testimonial-3 .testimonial-panel-wrap {
      padding: 50px 30px 30px;
  }

  .testimonial-3 .testimonial-item .testimonial__desc {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 20px;
  }
}

@media only screen and (max-width: 380px) {
  .testimonial-3 .testimonial-panel-wrap {
      padding-bottom: 70px;
  }

  .testimonial-3 .carousel-arrows .owl-nav .owl-prev,
  .testimonial-3 .carousel-arrows .owl-nav .owl-next {
      bottom: -40px;
  }
}


.about .about__img img {
  width: 0;
  opacity: 0;
  mix-blend-mode: multiply;
  transition: all 1.8s ease;
  transition-delay: 3s;
  border-radius: 4px;
}

.about-1 .video-banner img {
  border-radius: 4px;
}

.about-2 .btn__underlined:after {
  background-color: #121c45;
}

.about-2 .btn__underlined:hover:after {
  background-color: #ff5e14;
}

.about-2 .counter-item-wrapper {
  position: absolute;
  bottom: 40px;
  right: -60px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 20px solid #ffffff;
  opacity: 0;
  transform: scale(0);
  transition: all 1s ease 1.5s;
}

.about-2 .counter-item {
  background-color: #ff5e14;
  width: 190px;
  padding: 40px 20px 40px 40px;
  border-radius: 5px;
  margin-bottom: 0 !important;
}

.about-2 .counter-item h4 {
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 17px;
}

.animate-img.about__img:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  z-index: 1;
  opacity: 0;
  border-radius: 5px;
  background: #ff5e14;
  animation-delay: .4s;
  animation: slideOutBackground 1.7s ease;
}

.animate-img.about__img .counter-item-wrapper {
  opacity: 1;
  transform: scale(1);
}

.animate-img.about__img img {
  opacity: 1;
  width: 100%;
  transition-delay: 1s;
  transition: opacity .1s, transform .75s;
  animation: fadeInImage 1.5s ease;
}

@media only screen and (max-width: 992px) {
  .about-1 .video-banner {
      margin-right: 0;
  }

  .about-1 .video-banner img {
      width: 100%;
  }
}

@keyframes slideOutBackground {
  0% {
      width: 0;
      right: 100%;
  }

  50% {
      width: 100%;
      right: 0;
      opacity: 1;
  }

  100% {
      width: 0;
      right: 0;
      opacity: 1;
  }
}

@keyframes fadeInImage {
  0% {
      opacity: 0;
  }

  55% {
      opacity: 0;
  }

  75% {
      opacity: 1;
  }
}
